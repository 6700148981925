import React from 'react';
/**
 * Components
 */
import Banner from "./Banner/banner"
import About from "./About/about"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AgentsTeam from "./AgentsTeam/AgentsTeam"
import Contact from "./Contact/contact"
import MarketInsights from "./MarketInsights/marketInsights"
import { PageBreadcrumbs } from "../components/Common/utils"
import MeetTheTeam from './MeetTheTeam/meetTheTeam';
import BookAValuation from './BookAValuation/bookAValuation';
import BasicLayout from './BasicLayout/basicLayout';
import SoldProperty from './SoldProperties/SoldProperty';
import FeaturedPropertiesModule from './FeaturedPropertiesModule/FeaturedPropertiesModule';

const Modules = (props) => {
    const breadcrumb = PageBreadcrumbs(props.Page?.alias)
    return (
        <div className={props.Modules && props.Modules?.length > 0 && props.Modules[0]?.__typename === "GLSTRAPI_ComponentComponentsBannerSlider" ? "" : "page-top"}>

            {props.Page?.title !== "Market Insights" ?
                <></>
                :
                // <Breadcrumbs breadcrumbs={breadcrumb} />
                <></>
            }
              {props?.Page?.layout === "content_only_template" &&
              <BasicLayout title={props.Page?.title} content={props.Page?.content} />
        }
          
            {props?.Page?.choose_form === "book_a_valuation" &&
                            <BookAValuation 
                            Banner_Image={props?.Page?.banner_section?.banner_image}
                             Alias={props.Page?.Alias}
                             Content={props.Page?.content}
                             Title={props.Page?.title}
                               />
                            }
            {props.Modules && props.Modules?.length > 0 ? props.Modules.map((Module, i) => {
                return (
                    <div key={i} className={Module.__typename === "GLSTRAPI_ComponentComponentsTextBlockWithImage" ? "abt-bk" : ""}>
                        {Module.__typename === "GLSTRAPI_ComponentComponentsBannerSlider" &&
                            <Banner
                                Pagename={props.Page?.title}
                                Alias={props.Page?.alias}
                                {...Module}
                            />}
                            {Module.__typename === "GLSTRAPI_ComponentComponentsContact" &&
                            <Contact Content={props.Page?.content}
                            Title={props.Page?.title} Banner_Image={props?.Page?.banner_section?.banner_image} Alias={props.Page?.Alias} {...Module} />
                            }
                            
                        {Module.__typename === "GLSTRAPI_ComponentComponentsTextBlockWithImage" &&
                            <About Pagename={props.Page?.Pagename} Alias={props.Page?.Alias} {...Module} />
                        }

                        {Module.__typename === "GLSTRAPI_ComponentComponentsSelectCollection" && Module.choose_collection === "marketinsights" &&
                            <MarketInsights {...Module} />
                        }

                        {Module.__typename === "GLSTRAPI_ComponentModulesFeaturedProperties" &&
                            <FeaturedPropertiesModule {...Module} />
                        }

                        {Module.__typename === "GLSTRAPI_ComponentComponentsSelectCollection" && Module.choose_collection === "teams" &&
                            <MeetTheTeam {...Module} />
                        }

                        {Module.__typename === "GLSTRAPI_ComponentComponentsSelectModules" && Module.Choose_Module === "Properties" &&
                            <SoldProperty {...Module}/>
                        }


                        {Module.__typename === "GLSTRAPI_ComponentComponentsSelectModules" && Module.Choose_Module === "Team" &&
                            <AgentsTeam {...Module} />
                        }


                    </div>
                )

            }) : ''
            }
        </div>

    )
}
export default Modules
