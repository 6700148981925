import React, { useEffect, useState } from "react";
import { Container} from "react-bootstrap";
import {Link} from "gatsby"
import "./soldProperties.scss";
import axios from "axios"

import Slider from "react-slick";
import { useWindowSize } from '../../hooks/window-size';
import ImageTransform from "../../components/Common/ggfx-client/module/components/image-transform"


const SoldProperty = (props) => {
  const [similarProp,setSimilarProp]=useState([])

  const getitems = async url => {
      try {
        const { data } = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
          }})
        setSimilarProp(data)
      } catch (error) {
        // console.error(error)
      }
    }

  useEffect(()=>{
    let url;
    if(props?.Page_Type === "let"){
       url =process.env.GATSBY_STRAPI_SRC+"stb-lists/item/LetAgreedProperties"
  }
  else{
    url =process.env.GATSBY_STRAPI_SRC+"stb-lists/item/SoldProperties"
  }
      getitems(url)

  },[])
 
  const [windowWidth] = useWindowSize()

   

    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                  dots:true,
                },
              },
            {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots:true,
                },
              },
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots:true,
                },
              },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots:true,
            },
          },
        ],
      }

    //   const properties=data?.glstrapi.properties;

    //  const soldProp=properties?.slice(0,3);
    //  const soldPropMobile=properties?.slice(0,6)

  return (
    similarProp?.length > 0 &&
    <React.Fragment>
        <div className='sold-properties-container'>
          {/* {JSON.stringify(props)} */}
        <Container>
         <div className='sold-title'>
            <h3>{props?.title}</h3>
            {windowWidth>1199&&
            <Link to={props?.Cta?.custom_link} className="btn btn-outline-2">{props?.Cta?.label}</Link>
            }
        </div> 
        {windowWidth>1199&&
            <div className='sold-property-card'>
            {similarProp?.map((property,i)=>{
              let processedImages = JSON.stringify({})
              if (property?.imagetransforms?.images_Transforms) {
                processedImages = property.imagetransforms.images_Transforms
              }
              let uriStr = "property-for-sale/";
              let address1=property.display_address?.split(",");
              address1.pop();
              address1.pop();
              let displayAddress1=address1.join();
              return(
                <div className={`sold-items item-${i}`}>
                   <Link to={`/${uriStr}${property.slug}-${property.id}/`}>
                    <div className='image-container'>
                    
                                      <picture>
                                        <ImageTransform
                                          imagesources={property.images[0]?.url}
                                          renderer="srcSet"
                                          imagename={i === 0 ? "property.images.soldbig" : "property.images.sold"}
                                          attr={{
                                          alt:
                                          property.display_address +" - Crisp Cowley"

                                          }}
                                          imagetransformresult={processedImages}
                                          id={property.id}
                                          testparam={true}
                                           />
                                        </picture>
                       
                      
                              {/* <img src={property.images[0]?.url} alt={property.display_address}/> */}
                       
                        <span className='tag-name'>{property.status?.toUpperCase()}</span> 
                   </div>
                   </Link>
                   <div className="details-container">
                        <div className='description'>
                        <Link to={`/${uriStr}${property.slug}-${property.id}/`}>
                          {displayAddress1}
                        </Link>
                        </div>
                        <div className='price-value'>£{property.price?.toLocaleString()}</div>
                        <div className="no-of-bedrooms">{property.title}</div>
                   </div>
                </div>
              )
            })}
            </div> 
        } 

        {
            windowWidth<1199&&  
              <Slider {...settings}>
            {similarProp?.map((property,i)=>{
              let addressMobile=property.display_address?.split(",");
              addressMobile.pop();
              addressMobile.pop();
              let displayAddressMobile=addressMobile.join();
              let uriStr = "property-for-sale/";
              return (
                <div className='sold-mobile-container'> 
                    <div className="small-image-sold"> 
                    <Link to={`/${uriStr}${property.slug}-${property.id}/`}>            
                        <img className='sold-image-list' src={property.images[0]?.url} alt={displayAddressMobile +" - Crisp Cowley"}/>
                    </Link>
                        <span className='tag-name-mobile'>{property.status?.toUpperCase()}</span>
                    </div>
                    <div className="details-container-mobile">
                        <div className='description-mobile'>
                        <Link to={`/${uriStr}${property.slug}-${property.id}/`}>
                          {displayAddressMobile}
                          </Link>
                          </div>
                        <div className='price-value-mobile'>£{property.price?.toLocaleString()}</div>
                        <div className="no-of-bedrooms-mobile">{property.title}</div>
                   </div>               
                </div>
              )
              })}
              </Slider> 
        }   

            {windowWidth<1199&&
            <div className='sold-outline-mobile'>
            <Link to="/property/for-sale/in-bath/" className="btn btn-outline-2 mobile">View More Properties</Link>
            </div>
            }              
        </Container>
        </div>
    </React.Fragment>
  )
}

export default SoldProperty