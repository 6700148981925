import React from "react";
import "./about.scss"
import { Container, Row, Col } from "react-bootstrap"
import { GetPageURL } from "../Common/utils"
import { graphql, Link } from "gatsby"
import ContentBlock from "../Content/content";
import PlayVideo from "../PlayVideo/PlayVideo";
const About = ({text_block, media_block, Button_CTA, layout_type, background_color,}) => {

  const customClass=media_block.Custom_Class
    return(
        <div className={`${background_color} about-block ${layout_type}`}>
            <Container>
                        <Row className={layout_type === "left_large_width" || layout_type === "right_large_width" ? "d-flex justify-content-center align-items-center two-col-block" : ""}>
                            <Col
                            className={layout_type === "left_large_width" ? "col-pad-left" : layout_type === "right_large_width" ? "col-pad-right" : ""}
                             lg={{ span: layout_type === "full_width" ? 12 : layout_type === "left_large_width" || layout_type === "right_large_width" ? 6 : 12,
                             order: layout_type === "full_width" ? 1 : layout_type === "left_large_width" ? 2 : layout_type === "right_large_width" ? 1 : 1 }}
                             xs={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }}>
                            <h2>{text_block?.title}</h2>
                            <div className="editer-text">
                                <ContentBlock Content={text_block?.description} />
                            </div>
                            {Button_CTA && Button_CTA?.length > 0 &&
                              <div className="btn-blk d-md-flex d-block d-lg-flex">
                                {Button_CTA?.map((list, index) => {
                                    return (
                                        <Link key={index} target={list?.target === "new_window" ? "_blank" : ""} to={GetPageURL(list?.menu_item, list?.custom_link)} className={`${list?.theme} btn`}>
                                            {list?.label}
                                        </Link>
                                    )
                                })
                                }
                              </div>
                              }
                            </Col>
                            <Col
                            className={layout_type === "left_large_width" ? "col-pad-right" : layout_type === "right_large_width" ? "col-pad-left" : "about-block-component"}
                            lg={{ span: layout_type === "full_width" ? 12 : layout_type === "left_large_width" || layout_type === "right_large_width" ? 6 : 12,
                            order: layout_type === "full_width" ? 2 : layout_type === "left_large_width" ? 1 : layout_type === "right_large_width" ? 2 : 2 }}
                            xs={{ span: 12, order: 2 }} md={{ span: 12, order: 2 }}
                             >
                            <div style={{backgroundImage: `url(${media_block?.media_file?.url})` }} className={`video-blk ${customClass}`}>
                                {media_block?.video_url &&
                                <PlayVideo title={text_block?.title} videourl={media_block?.video_url}></PlayVideo>
                                
}
                            </div>
                            
                            </Col>
                            </Row>
                            </Container>

        </div>
    )
}

export default About
export const componentComponentsTextBlockWithImage = graphql`
    fragment ComponentComponentsTextBlockWithImage on GLSTRAPI_ComponentComponentsTextBlockWithImage {
        id
        text_block {
          description
          title
        }
        Button_CTA {
            custom_link
            theme
            target
            label
            menu_item {
              slug
              parent {
                slug
                parent {
                  slug
                }
              }
            }
          }
        layout_type
        background_color
        media_block {
          media_file {
            alternativeText
            url
          }
          video_url
        }
    }
`