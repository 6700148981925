import React, {useState} from "react";
import { useStaticQuery, graphql } from "gatsby";
import "./meetTheTeam.scss"
import { Container, Row, Col, Button } from "react-bootstrap"
// import moment from 'moment';
import ContactModalForm from "../Forms/ContactModalForm"
import ImageTransform from "../../components/Common/ggfx-client/module/components/image-transform";
import ContentBlock from "../Content/content";
import Modal from 'react-bootstrap/Modal';
const MeetTheTeam = (props) => {
    const [showForm, setShowForm] = useState(false);
    const [indexI, setIndex] = useState();
    const openform = (i) => {
        if(indexI === i){
            setIndex()
            setShowForm(false)
        }
        else {
            setIndex(i)
            setShowForm(true)
        }
    }
    const data = useStaticQuery(graphql`
    query {
        glstrapi {
            teams(publicationState: LIVE, where: { publish: true }) {
                name
                id
                publish
                designation
                imagetransforms
                email
                video_url
                tile_image {
                    alternativeText
                    url
                }    
              
            
            }
       
        }
    }
    `)
    return (
        <React.Fragment>
            <div className="meettheteam">
                <Container>
                    <Row>
                        <Col className="max-width">
                            <h1>{props.title}</h1>
                            <ContentBlock Content={props.description} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-md-flex flex-blk">
                                {data?.glstrapi?.teams?.map((item, index) => {
                                    let processedImages = JSON.stringify({});
                                    if (item?.imagetransforms?.Tile_image_Transforms) {
                                        processedImages = item.imagetransforms.Tile_image_Transforms;
                                    }
                                return(
                                    <div key={index} className="item">
                                        {item?.video_url &&
                                            <Button className="play-btn"></Button>
                                        }
                                        <div className="img-blk">
                                        <ImageTransform imagesources={item?.tile_image?.url}
                                         renderer="srcSet"
                                          imagename='teams.Tile_image.image'
                                                                attr={{ alt:item?.name+" - Crisp Cowley", className: '' }}
                                                                imagetransformresult={processedImages}
                                                                 id={item.id} >

                                                            </ImageTransform>
                                            {/* <img src={item?.tile_image?.url} alt={item?.tile_image?.alternativeText?item?.tile_image?.alternativeText+" - Crisp Cowley":item?.name+" - Crisp Cowley"} /> */}
                                        </div>
                                        <div className="txt-blk">
                                            <h4 className="title">{item?.name}</h4>
                                            <div className="designation">{item?.designation}</div>
                                            <a onClick={() => openform(index)} className="email">Email Directly</a>
                                        </div>
                                        <Modal className="teamdetails popupForm" backdrop="static" size="lg" show={showForm && index === indexI} onHide={() => { setShowForm(false) }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title> <h2>Email Directly</h2></Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body> 
                                                <ContactModalForm agent={item}/>  
                                            </Modal.Body>

                                        </Modal>
                                    </div>
                                )})}

                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        </React.Fragment>
    )
}

export default MeetTheTeam;