import React from "react";
import "./contact.scss"
import { Container, Row, Col, Button } from "react-bootstrap"
// import { PageBreadcrumbs } from "../Common/utils"
// import Breadcrumbs from '../Breadcrumbs/index';
import { Link } from "gatsby";
import ContentBlock from "../Content/content";
import { Instagram, Twitter, Facebook, Linkedin } from "../icons"
import ContactForm from "../Forms/ContactForm"
import { useStaticQuery, graphql } from "gatsby"
const Contact = (props) => {
    // const breadcrumb = PageBreadcrumbs(props.Page?.alias)
    const data = useStaticQuery(graphql`
  query {
    glstrapi {
      siteConfig(publicationState: LIVE) {
        instagram_link
        linkedin_link
        twitter_link
        facebook_link
      }
      
      }
    }
  `)
  const mapLoc="https://www.google.com/maps/place/1+Wood+St,+Bath+BA1+2JQ,+UK/@51.3832521,-2.3627735,17z/data=!3m1!4b1!4m5!3m4!1s0x4871811464b5e757:0x4f3ed909801e557c!8m2!3d51.3832521!4d-2.3627735"
    return (
        <div className="contact">
            <div className="contact-bg" style={{ backgroundImage: `linear-gradient( rgba(0,0,0,.5), rgba(0,0,0,.5) ),url(${props?.Banner_Image?.url})` }}></div>
            <Container>
                <Row>
                    <Col>
                        <div className="d-block d-lg-flex align-items-center">
                            <div className="left">
                            <div className="form">
                                   <ContactForm Content={props.Content}
                             Title={props.Title} />
                             </div>
                            </div>
                            <div className="right">
                            <div className="text">
                                    <div className="contact-info-tablet">
                                      <div>
                                        <h6>contact details</h6>
                                        <ContentBlock Content={props.address} />
                                        <a href={mapLoc} target="_blank" className="view-map-contact">View on a map</a>
                                        <a href={"tel:"+props.telephone}>{props.telephone}</a>
                                      </div> 
                                      <div className="opening-hour-mobile"> 
                                        <h6>Opening Hours</h6>
                                        <ContentBlock Content={props.opening_hours} />
                                        </div>
                                      </div>

                                        <h6>social</h6>                                      
                                        <ul className="social-links">
                                          <li><a href={data?.glstrapi?.siteConfig?.instagram_link} target="_blank"><Instagram /></a></li>
                                          <li><a href={data?.glstrapi?.siteConfig?.twitter_link} target="_blank"><Twitter /></a></li>
                                          {/* <li><Link to={data?.glstrapi?.siteConfig?.facebook_link}><Facebook /></Link></li> */}
                                          <li><a href={data?.glstrapi?.siteConfig?.linkedin_link} target="_blank"><Linkedin /></a></li>
                                        </ul>
                                  </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Contact