import React,{useEffect,useState} from "react";
import "./banner.scss"
import Slider from "react-slick";
import { Container, Row, Col, Button } from "react-bootstrap"
import { GetPageURL } from "../Common/utils"
import { graphql, Link } from "gatsby"
import PlayVideo from "../PlayVideo/PlayVideo"
import ReactPlayer from 'react-player';
import { isMobileOnly } from "react-device-detect";
const Banner = ({ Add_Slider }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };


    const [showButton, setShowButton]=useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () =>
    setShowButton(window.pageYOffset)
    )

  }, [])


    return (
        <div className="banner">
            <Slider {...settings}>
                {Add_Slider?.map((item, index) => {
                    let video = item?.video_url 
                    return (<div key={index}>
                        <div className="banner-item" style={{ backgroundImage: `url(${item.banner_image?.url?item.banner_image?.url:""})` }}>
                            <div className="html-vid">
                        <ReactPlayer 

url={item?.Upload_Video?.url}
muted={true}
loop={true}
controls={false}
autoplay={true}
playsinline={true}
playing={true}
// onEnded={() => { setHTMLVideo(false); setPlayvideo(false) }}
// onClick={() => showControls()}
/>
</div>
                            {/* <img src={BannerImg} alt="Banner" /> */}
                            <Container className="banner-container">
                                <Row>
                                    <Col>
                                        <div className="banner-content d-lg-flex d-block">
                                            <div>
                                                <h1>{item?.title}</h1>
                                            </div>
                                            <div className={`btn-blk d-md-flex d-block d-lg-block`}>
                                                {item?.ctas?.map((list, index) => {
                                                    let ctaLabel;
                                                    if(list.label==="BOOK A VALUATION"){
                                                        ctaLabel="VALUATION"
                                                    }
                                                    else if(list.label==="FIND A PROPERTY"){
                                                        ctaLabel="PROPERTIES"
                                                    }
                                                    return (
                                                        <>
                                                        {isMobileOnly?
                                                        <Link key={index} target={list?.target === "new_window" ? "_blank" : ""} to={GetPageURL(list.menu_item, list.custom_link)} className={`${list?.theme} btn`}>
                                                            {ctaLabel}
                                                        </Link>
                                                        :
                                                        <Link key={index} target={list?.target === "new_window" ? "_blank" : ""} to={GetPageURL(list.menu_item, list.custom_link)} className={`${list?.theme} btn`}>
                                                            {/* {list?.label} */}
                                                            {ctaLabel}
                                                        </Link>
                                                        }
                                                        </>
                                                    )
                                                })
                                                }

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            {video && 
                            <PlayVideo title={item?.title} videourl={video} />
                            }   
                            {/* <Button className="play-btn"></Button> */}
                        </div>

                    </div>
                    )
                })}


            </Slider>
            {showButton>555&&
            <div className="banner-cta-mobile">
            {Add_Slider?.map((item, index)=>{
                return(
                    <div className="banner-cta-but">
                            {item?.ctas?.map((list, index) => {
                                let footerLabel;
                                if(list.label==="BOOK A VALUATION"){
                                    footerLabel="VALUATION"
                                }
                                else if(list.label==="FIND A PROPERTY"){
                                    footerLabel="PROPERTIES"
                                }
                                return (
                                    <>
                                    {isMobileOnly?
                                    <Link key={index} target={list?.target === "new_window" ? "_blank" : ""} to={GetPageURL(list.menu_item, list.custom_link)} className={`${list?.theme} btn`}>
                                        {footerLabel}
                                    </Link>
                                    :
                                    <Link key={index} target={list?.target === "new_window" ? "_blank" : ""} to={GetPageURL(list.menu_item, list.custom_link)} className={`${list?.theme} btn`}>
                                        {list?.label}
                                    </Link>
                                    }
                                    </>
                                 )
                                })
                                }
                    </div> 
                )
             })}
            </div>
            }
        </div>
    )
}

export default Banner

export const componentComponentsBannerSlider = graphql`
    fragment ComponentComponentsBannerSlider on GLSTRAPI_ComponentComponentsBannerSlider {
        id
        Add_Slider {
          description
          title
          video_url
          Upload_Video {
            alternativeText
            url
          }
          banner_image {
            alternativeText
            url
          }
          ctas {
            custom_link
            label
            menu_item {
                slug
                parent {
                  slug
                  parent {
                    slug
                  }
                }
              }
            target
            theme
          }
        }
    }
`