import * as React from "react";
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet";
import PageModules from '../components/modules';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import { isSafari, isIOS, isMacOs } from "react-device-detect"

const DefaultTemplate = props => {

  const Modules = props.data?.glstrapi?.page?.add_components
  const Page = props.data?.glstrapi?.page

  
    return (
      <Layout layout={Page?.layout}>
        <SEO
        location={props.location}
          title={Page ? Page?.seo_meta?.title : ""}
          description={Page ? Page.seo_meta?.description : ""}
        />
        <Helmet
          bodyAttributes={{
            class: `templates-default-template alias-${
              Page ? Page?.alias : ""
            } homepage ${Page ? Page?.page_class : ""} ${(isSafari === true || isIOS === true || isMacOs === true)? "mac-device":""}`,
          }}
        />
        {Page ? (
          <PageModules
            Modules={Modules}
            Page={Page}
            
          />
        ) : null}
      </Layout>
    )
  
}

export default DefaultTemplate

export const pageQuery = graphql`
  query DeafultQuery($id: ID!) {
    glstrapi {
      page(id: $id, publicationState: LIVE) {
        title
      publish
      seo_meta {
        description
        title
        image {
          alternativeText
          url
        }
      }
      page_class
      layout
      content
      choose_form
      alias
      banner_section {
        banner_image {
          alternativeText
          url
        }
        show_search
        show_reviews
        content
        title
        button_cta_2 {
          theme
          target
          label
          menu_item {
            slug
            parent {
              slug
              parent {
                slug
              }
            }
          }
          custom_link
        }
        button_cta_1 {
          theme
          target
          label
          menu_item {
            slug
            parent {
              slug
              parent {
                slug
              }
            }
          }
          custom_link
        }
      }
      
      add_components {
        ... on GLSTRAPI_ComponentComponentsTextBlockWithImage {
          __typename
          ...ComponentComponentsTextBlockWithImage
          media_block {
            Custom_Class
          }
         
        }

        ... on GLSTRAPI_ComponentComponentsBannerSlider {
          __typename
          ...ComponentComponentsBannerSlider

        }

        ... on GLSTRAPI_ComponentComponentsContact {
          __typename
          id
          email
          title
          content
          telephone
          address
          opening_hours
        }

        ... on GLSTRAPI_ComponentComponentsSelectCollection {
          __typename
          id
          title
          description
          choose_collection
        }

        ... on GLSTRAPI_ComponentModulesFeaturedProperties {
          __typename
          id
          title
          cta {
            theme
            target
            label
            id
            custom_link
            menu_item {
              link_type
              label
              slug
              parent {
                slug
                label
                parent {
                  slug
                  label
                }
              }
            }
          }
          properties {
            imagetransforms
            status
            display_address
            slug
            id
            images
            price
            title
          }
        }

        ... on GLSTRAPI_ComponentComponentsSelectModules {
          __typename
          id
          Choose_Module
          description
          Page_Type
          Cta {
            custom_link
            label
            menu_item {
              slug
              parent {
                slug
                parent {
                  slug
                }
              }
            }
            target
            theme
          }
          title
        }
      
                  
      }


      }
    }
  }
`
