import React, { useState, useRef } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import TextField from "./Inputs/TextField";
import TextArea from "./Inputs/TextArea";
import Recaptcha from "./Inputs/Recaptcha";
import { postFormData } from "./api/Api";
import { ScrollToTop, SendMail, FormValidation } from "../Common/utils";
import _ from "lodash";
import "./Form.scss";

const ContactModalForm = (props) => {
    const recaptchaRef = useRef();
    const initFormState = {
        formname: "Team Form",
        name: '',
        telephone: '',
        email: '',
        address: '',
        message: '',
        agent_name: (props.agent?.name)? props.agent?.name : '',
        agent_email: props.agent?.email? props.agent?.email : ''
    }
    const validationFields = ['name', 'email', 'telephone', 'address'];
    const [formState, setFormState] = useState(initFormState);
    const [errors, setErrors] = useState({});
    const [errorFlag, setErrorFlag] = useState(false);
    const [showthankyou, setThankyou] = useState(false);
    const [btnProcess, setBtnProcess] = useState(false);
    const myRef = React.createRef();


    const handleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        var validation = FormValidation(formState, validationFields);
        if (validation.status) {
            setErrorFlag(false)
            setErrors(validation.errors)
            const token = await recaptchaRef.current.executeAsync();
            if (token) {
                setBtnProcess(true)
                var postData = _.cloneDeep(formState)
                postData['extra'] = formState;
                recaptchaRef.current.reset();
                postFormData(postData).then(async apiRes => {
                    setThankyou(true)
                    setFormState(initFormState)
                    setBtnProcess(false);
                    // scrollTop();
                    // myRef.current.scrollIntoView({ behavior: 'smooth'})

                    setTimeout(() => {
                        setThankyou(false)
                    }, 2000)
                    // GTM tracking event 
                    // gtmEvent({ formType: 'Property Valuation', formName: 'Home Visit Valuation' })
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                    event: "formSubmit",
                    formType: "form-team",
                    formId: "form-team",
                    formName: "Team Form",
                    formLabel: "Team Form",
                    })
                    // lets send mail
                    postData['email_temp_user'] = 'team_user';
                    postData['email_temp_admin'] = 'team_admin';
                    postData['email_subject_user'] = 'Thank you for your enquiry';
                    postData['email_subject_admin'] = 'From Web: Individual Enquiry';
                    postData['g-recaptcha-response'] = token;
                   await SendMail(postData);
                });
            }
        } else {
            setErrors(validation.errors)
            setErrorFlag(true)
            // scrollTop();
            // myRef.current.scrollIntoView({ behavior: 'smooth',block: 'center' })

        }
    }

    const scrollTop = () => {
        ScrollToTop(document.getElementById('form-section'), 100)
    }
    const errClass = (field) => {
        if (errors[field]) {
            return 'field-error';
        }
        return ''
    }
    return (
        <React.Fragment>
            <section className='contact-modal-form' id="form-section" >

                <Row>
                    <Col md={12}>
                        <div className='heading'>
                            <h2>{props.Title}</h2>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className='mortgage-wrapper' ref={myRef}>
                            <div className='form-wrapper'>
                                {showthankyou && <div className="alert alert-success">
                                    Thank you for your interest. A member of our team will contact you shortly.
                                </div>}
                                {
                                    errorFlag &&
                                    <div className="alert alert-danger alert-error">Highlighted fields are required | invalid</div>
                                }

                                <div className='form-inputs'>
                                    <Row>
                                    <Col md={6}>
                                    <TextField
                                        label="Full Name"
                                        name={`name`}
                                        placeholder=""
                                        onChange={handleChange}
                                        value={formState[`name`]}
                                        className={errClass(`name`)}
                                    />
                                    </Col>
                                    <Col md={6}>
                                    <TextField
                                        type="number"
                                        label="Telephone"
                                        name={`telephone`}
                                        placeholder=""
                                        onChange={handleChange}
                                        className={errClass(`telephone`)}
                                        value={formState[`telephone`]}
                                    />
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col md={6}>
                                    <TextField
                                        label="Email Address"
                                        name={`email`}
                                        placeholder=""
                                        onChange={handleChange}
                                        className={errClass(`email`)}
                                        value={formState[`email`]}
                                    />
                                    </Col>
                                    <Col md={6}>
                                    <TextField
                                        label="Address"
                                        name={`address`}
                                        placeholder=""
                                        onChange={handleChange}
                                        value={formState[`address`]}
                                        className={errClass(`address`)}
                                    />
                                    </Col>
                                    </Row>
                                </div>
                                <TextArea
                                    label="Message"
                                    rows={5}
                                    name="message"
                                    placeholder=""
                                    onChange={handleChange}
                                    value={formState[`message`]}
                                />
                                <Recaptcha recaptchaRef={recaptchaRef} />
                                <Button type="submit"
                                    disabled={btnProcess}
                                    onClick={(event) => { handleSubmit(event) }}
                                    className='btn outline width-100'
                                >Email to {props.agent?.name}</Button>
                            </div>
                        </div>
                    </Col>
                </Row>


            </section>
        </React.Fragment >
    )

}
export default ContactModalForm;