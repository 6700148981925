import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { GetPageURL } from "../Common/utils"
import "./AgentsTeam.scss";
import ImageTransform from "../Common/ggfx-client/module/components/image-transform";

const AgentsTeam = (props) => {
  const data = useStaticQuery(graphql`
  query {
      glstrapi {
          teams(publicationState: LIVE, where: { publish: true }) {
              name
              id
              publish
              designation
              email
              category
              video_url
              tile_image {
                  alternativeText
                  url
              }  
              imagetransforms
          }
     
      }
  }
  `)

  


  const teamData=data.glstrapi.teams


  const lettingsData=teamData.filter(data=>data.category==="Lettings")
  const salesData=teamData.filter(data=>data.category==="Sales")

  console.log(salesData,"sales")

  const team_Data=props.Page_Type==="let"?lettingsData:salesData
  // const findData=teamData?.find(d=>d.name==="Michael Swait");

  const filteredData=team_Data;

  // const filteredData=team_Data?.filter(d=>d.name!=="Michael Swait");
  // const pop=filteredData.splice(1,0,findData);

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: filteredData.length>3?true:false,
    arrows: false,
    autoplay: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: filteredData.length>3?true:false,
          dots: true,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: filteredData.length>2?true:false,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: filteredData.length>1?true:false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: filteredData.length>1?true:false,
          dots: true,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <section className="agent-team-section">

        <Container>
          <Row>
            <Col md={12} lg={12} className="order-lg-1">
              <div className="d-flex team-head-blk">
                <h2>Meet the Team</h2>
                <div className="d-none d-xl-block">
                {props.Cta &&
                  <Link target={props.Cta?.target === "new_window" ? "_blank" : ""} to={GetPageURL(props.Cta?.menu_item, props.Cta?.custom_link)} className={'d-none d-md-block outline btn'}>
                    {props.Cta?.label}
                  </Link>
                }
                </div>

              </div>
            </Col>

          </Row>
          <Slider {...settings}>
            {filteredData?.map((item, index) => {
               let processedImages = JSON.stringify({});
               if (item?.imagetransforms?.Tile_image_Transforms) {
                   processedImages = item.imagetransforms.Tile_image_Transforms;
               }

               return(
              <div className="team-card">
                <div className="item-image-wrrapper img-hover-zoom">
                  {/* <picture> */}
                    {/* <source media="(min-width:992px)" srcSet={item?.tile_image?.url} /> */}
                    {/* <source media="(min-width:768px)" srcSet={item?.tile_image?.url} /> */}
                    <ImageTransform imagesources={item?.tile_image?.url}
                                         renderer="srcSet"
                                          imagename='teams.Tile_image.homeimage'
                                                                attr={{ alt:item?.name+" - Crisp Cowley", className: '' }}
                                                                imagetransformresult={processedImages}
                                                                 id={item.id} >

                                                            </ImageTransform>
                    {/* <img src={item?.tile_image?.url} alt={item?.tile_image?.alternativeText?item?.tile_image?.alternativeText+" - Crisp Cowley":item.name+" - Crisp Cowley" } /> */}
                  {/* </picture> */}
                  {/* <span  onClick={(e) => {setPlay(true)}} className="video-btn"><i class="icon-video"></i></span> */}
                </div>
                <div className="team-card-content">
                  <h6>{item.name}</h6>
                  <div className="designation">{item.designation}</div>
                  {/* <div className="email">Email</div> */}
                </div>
              </div>
               )

})}
          </Slider>
          <Row>
            <Col md={12} lg={12} className="d-xl-none">
              <div className="d-flex team-head-blk mobile">
                {props.Cta &&
                  <Link target={props.Cta?.target === "new_window" ? "_blank" : ""} to={GetPageURL(props.Cta?.menu_item, props.Cta?.custom_link)} className={'mob-view outline btn'}>
                    {props.Cta?.label}
                  </Link>
                }

              </div>
            </Col>

          </Row>
        </Container>
        {/* <PlayVideo
          isOpen={isPlay}
          isCloseFunction={setPlay}
          videoId="V8ksSGxo6no"
          isAutoPlay={1}
        /> */}
      </section>
    </React.Fragment>
  )
}

export default AgentsTeam;