import React from "react";
import "./bookAValuation.scss"
import { Container, Row, Col, Button } from "react-bootstrap"
import ContentBlock from "../Content/content";
import BookAValuationForm from "../Forms/BookAValuation"
const BookAValuation = (props) => {
    return(
        <div className="book-a-valuation">
            <div className="book-a-valuation-bg" style={{ backgroundImage: `url(${props?.Banner_Image?.url})`}}></div>
            
            <Container>
                        <Row>
                        <Col>
                        <div className="form">
                        <h1>{props.Title}</h1>
                        <ContentBlock Content={props?.Content} />
                        <BookAValuationForm />
                        </div>
                           </Col>
                            
                            </Row>
                            </Container>

        </div>
    )
}

export default BookAValuation