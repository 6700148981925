import React from "react";
import ReactMarkdown from "react-markdown"
import Content from '../Content/content';
import { Container, Row, Col } from "react-bootstrap"
import "./basicLayout.scss"
const BasicLayout = (props) => {
    return(
        <div className='basic-layout'>
              <Container>
              <Row>
                  <Col>
                  <h1>{props?.title}</h1>
            <Content Content={props?.content}/>
            </Col>
            </Row>
            </Container>
            </div>
    )
}
export default BasicLayout