import React, {useState} from "react";
import { useStaticQuery, graphql } from "gatsby";
import "./marketInsights.scss"
import { Container, Row, Col, Button } from "react-bootstrap"
import moment from 'moment';
import ContentBlock from "../Content/content";
import PlayVideo from "../PlayVideo/PlayVideo";
import Pagination from "react-js-pagination";
// import "bootstrap/less/bootstrap.less";
const MarketInsights = (props) => {
    
    const data = useStaticQuery(graphql`
    query {
        glstrapi {
            blogs(publicationState: LIVE, where: { publish: true }, sort: "post_on:DESC") {
                title
                publish
                video_url
                tile_image {
                  alternativeText
                  url
                }
                post_on
              }
       
        }
    }
    `)
    const recordPerPage = 6;

    // total number of the records
    const totalRecords = data?.glstrapi?.blogs?.length;

    // range of pages in paginator
    const pageRange = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [blogdata, setBlogdata] = useState(data?.glstrapi?.blogs?.slice(0, recordPerPage));

    // total records per page to display
    

    // handle change event
    const handlePageChange = pageNumber => {
        setCurrentPage(pageNumber);
        // call API to get data based on pageNumber
        const indexOfLastTodo = pageNumber * recordPerPage;
        const indexOfFirstTodo = indexOfLastTodo - recordPerPage;
        setBlogdata(data?.glstrapi?.blogs?.slice(indexOfFirstTodo, indexOfLastTodo))
        window.scroll(0, 0)
    }
    return (
        <React.Fragment>
            <div className="marketinsights">
                <Container>
                    <Row>
                        <Col className="max-width">
                            <h1>{props.title}</h1>
                            <ContentBlock Content={props.description} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-md-flex flex-blk">
                                {blogdata?.map((item, index) => (
                                    <div key={index} className="item">
                                       
                                        <div className="img-blk">
                                            <img src={item?.tile_image?.url} alt={item?.tile_image?.alternativeText?item?.tile_image?.alternativeText+" - Crisp Cowley":item?.title+" - Crisp Cowley"} />
                                            {item?.video_url &&
                                            <PlayVideo title={props.title} videourl={item?.video_url} ></PlayVideo>
                                        }
                                        </div>
                                        <div className="txt-blk">
                                            <h4 className="title">{item?.title}</h4>
                                            <p className="date">{moment(item?.post_on).format("MM/DD/YYYY")}</p>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </Col>
                    </Row>
                    {recordPerPage < data?.glstrapi?.blogs?.length &&
                    <Row>
                        <Col>
                            <Pagination
                                itemClass="page-item" // add it for bootstrap 4
                                linkClass="page-link" // add it for bootstrap 4
                                activePage={currentPage}
                                itemsCountPerPage={recordPerPage}
                                totalItemsCount={totalRecords}
                                pageRangeDisplayed={pageRange}
                                onChange={handlePageChange}
                            />
                        </Col>
                    </Row>}
                </Container>

            </div>
        </React.Fragment>
    )
}

export default MarketInsights;